import React from 'react';
import { Shield } from 'lucide-react';

const Terms = () => {
  return (
    <div className="pt-20 pb-12">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-center mb-8">
          <Shield className="h-12 w-12 text-red-600 mr-4" />
          <h1 className="text-3xl font-bold">Terms of Service</h1>
        </div>

        <div className="prose max-w-none">
          <section className="mb-8">
            <h2 className="text-xl font-semibold mb-4">Disclaimer of Liability</h2>
            <p className="text-gray-600 mb-4">
              RadonMap.ca provides general information about radon levels and potential risks based on publicly available data. 
              This information is intended for educational purposes only and should not be considered as professional advice or 
              a substitute for professional radon testing services.
            </p>
            <p className="text-gray-600 mb-4">
              We do not guarantee the accuracy, completeness, or reliability of the data presented. Radon levels can vary 
              significantly between properties and over time, even within the same area.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-xl font-semibold mb-4">Professional Consultation</h2>
            <p className="text-gray-600 mb-4">
              For accurate assessment of radon levels in your property, we strongly recommend:
            </p>
            <ul className="list-disc pl-6 text-gray-600 mb-4">
              <li>Consulting certified radon measurement professionals</li>
              <li>Conducting proper long-term radon testing</li>
              <li>Following Health Canada guidelines for testing and mitigation</li>
              <li>Seeking professional advice for any mitigation measures</li>
            </ul>
          </section>

          <section className="mb-8">
            <h2 className="text-xl font-semibold mb-4">Data Usage</h2>
            <p className="text-gray-600 mb-4">
              The information provided on RadonMap.ca is compiled from public sources and general geological data. 
              This data should be used only as a general guide to understand potential radon risk in your area.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-xl font-semibold mb-4">Limitation of Liability</h2>
            <p className="text-gray-600 mb-4">
              RadonMap.ca, its owners, operators, and affiliates shall not be liable for any damages or losses 
              resulting from the use of this website or the information it contains. This includes, but is not 
              limited to, direct, indirect, incidental, punitive, and consequential damages.
            </p>
          </section>

          <div className="bg-gray-50 p-6 rounded-lg mt-8">
            <p className="text-gray-600 text-sm">
              By using RadonMap.ca, you acknowledge and agree to these terms of service. We recommend reviewing 
              these terms periodically as they may be updated without notice.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Terms;