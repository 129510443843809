const GOHIGHLEVEL_API_KEY = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJsb2NhdGlvbl9pZCI6IkZQOU5sM0d6RENCVWw3dXU1enNRIiwidmVyc2lvbiI6MSwiaWF0IjoxNzM2MTk0MTc4ODA5LCJzdWIiOiJ5TUVZWFNmcmN6Vlo4cjBzTm93eCJ9.BxfaAweqZVw8pRsjO21W1tM4FcCeYUzGxRIyc_uRZCM';
const LOCATION_ID = 'FP9Nl3GzDCBUl7uu5zsQ';

interface Contact {
  email: string;
  phone: string;
  firstName?: string;
  lastName?: string;
  address?: string;
  postalCode?: string;
  source?: string;
  tags?: string[];
}

export const createContact = async (contact: Contact) => {
  try {
    const response = await fetch('https://rest.gohighlevel.com/v1/contacts/', {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${GOHIGHLEVEL_API_KEY}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        locationId: LOCATION_ID,
        ...contact
      })
    });
    
    if (!response.ok) {
      throw new Error('Failed to create contact');
    }
    
    return await response.json();
  } catch (error) {
    console.error('Error creating contact:', error);
    throw error;
  }
};